@import url(https://fonts.googleapis.com/css2?family=Anek+Latin&family=Archivo&family=Average+Sans&family=Coiny&family=Inter:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal-content {
  border-radius: 0;
}

.ant-modal {
  width: 100% !important;
  margin: 0;
  max-width: 100%;
  top: 0;
  height: 100%;
}

.ant-modal-body {

  padding: 0;
}

.custom-body {
  width: 100%;
  height: 100%;
  background-color: #F9BCA0;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  padding: 61px 44px;
}

@media (min-width: 768px) {
  .ant-modal {
    width: 100% !important;
    max-width: 900px !important;
    margin: 0 auto;
    top: 100px;
    height: auto;
  }

  .custom-body {
    width: 100%;
    height: 100%;
    background-size: contain;
    padding: 55px 50px 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-modal-content {
    border-radius: 60px;
    overflow: hidden;
  }
}

.ReactCollapse--collapse {
  transition: all 100ms;
}
/* font-family: 'Anek Latin', sans-serif;
font-family: 'Archivo', sans-serif;
font-family: 'Average Sans', sans-serif;
font-family: 'Coiny', cursive;
font-family: 'Inter', sans-serif; */
